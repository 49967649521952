// jQuery
import $ from 'jquery'

/**
 * 移除没有内容的块导航
 * @param {Object} container 块标签容器元素
 * @param {Object} wrapper navTag 容器元素
 */
export function emptyBlkRemove(options = {}) {
  let {
    container,
    wrapper
  } = options

  let blks = $(container).find('.display-block')

  if (blks.length === 0) {
    return
  }

  let tags = $(wrapper).children('.yui-tag-nav')
  let rmIndex = [] // 待移除空块的索引

  for (let i = 0; i < tags.length; i++) {
    // 查找空块索引
    if (blks.eq(i).children('li').length === 0) {
      rmIndex.push(i)
    }
  }

  for (let i = 0; i < rmIndex.length; i++) {
    tags.eq(rmIndex[i]).remove()
    blks.eq(rmIndex[i]).remove()
  }

  if (rmIndex.length === tags.length) {
    // 全部块为空块
    $(wrapper).remove()
  }
}