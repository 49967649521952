// jQuery
import $ from 'jquery'
// 页面样式
export * from 'css/page/index.scss'
// 组件
import {
  fter0Component
} from 'components/footer/0'
import {
  loadBtn0Component
} from 'components/btn/load/0'
import {
  loadingMaskComponent
} from 'components/mask/loading'
import {
  nav0Component
} from 'components/nav/0'
export * from 'components/btn/download/0'
export * from 'components/btn/download/2'
export * from 'components/btn/gift/0'
export * from 'components/header/0'
// API
import {
  getRank,
} from 'api'
// 常量
import * as constants from 'constants'
// 动画
import {
  tagBlkInit,
} from 'js/animation/index'
// 公共方法
import {
  broadcastInit,
  dataBus,
  download,
  getGameTemp,
  LoadMore,
  memberShip,
  show,
  swiper,
} from 'js/common'
// 工具方法
import * as utils from 'utils'

/**
 * 绑定 DOM 事件
 */
function bindEvts() {
  loadMoreBtnClk()
  tagBlkInit({
    aTag: $('#hotclass .section-more').parent('a')[0],
    container: document.querySelector('#hotclass .show'),
    wrapper: document.querySelector('.yui-tags-navWrapper-blue')
  })
}

/**
 * 组件初始化
 */
function componentsInit() {
  fter0Component.init()
  nav0Component.init()
}

function loadMoreBtnClk() {
  let btn = document.querySelector('.yc-loadbtn0')
  let target = document.querySelector('#more > ul')

  if (!(btn && target)) {
    return utils.log.e(constants.tips.console.domElNotFound)
  }

  let pageIndex = 1 // 数据表页码
  let loader = new LoadMore({
    createNode,
  })

  loader.scrollListen({
    gap: 200,
    scrollListenEl: btn,
    scrollTrigger: btn,
  })

  $(btn).one('click', handler)

  function handler() {
    loadBtn0Component.loading()
    loader.done(() => {
        loadBtn0Component.recover()
        $(btn).one('click', handler)
        pageIndex++
      })
      .failed(() => {
        loadBtn0Component.fail()
        $(btn).one('click', handler)
      })
      .completed(() => {
        loadBtn0Component.complete()
        $(btn).off('click', handler)
      })
      .start({
        api: getRank,
        data: {
          clientType: dataBus.get({
            key: 'browser',
          }).ios ? 'ipa' : 'apk',
          columns: 'categoryidname,downloadlink,downloadnum,filesize,link,name,proscore,thumnaillogofile,operationstatus',
          pageIndex,
          pageSize: 20,
          rankCode: btn.getAttribute(constants.propertyName.rank.code),
          type: btn.getAttribute(constants.propertyName.rank.type),
        },
        target,
      })
  }

  function createNode(o) {
    let isIos = dataBus.get({
      key: 'browser'
    }).ios

    let color = o.proscore ? getGameTemp(o.proscore) : ''
    let {
      link,
    } = o.operationstatus === 2 || o.operationstatus === 7 ? { link: 'javascript:void(0)' } : download.parsePackInfo(o.downloadlink)
    let imgSrc = `${resourceprefix}${o.thumnaillogofile ? o.thumnaillogofile : ''}` // logo路径
    let name = o.name ? o.name : ''
    let node = $(`
      <li>
        <div class="yui-ccard-gamebase">
          <a href="${o.link ? o.link : 'javascript:void(0)'}">
            <div class="icon-box">
              <img class="img-wrapper" src="${imgSrc}" alt="${name}">
            </div>
            <div class="info-box">
              <div class="high-layer">${name}</div>
              <div class="middle-layer">${o.categoryidname ? o.categoryidname : '--'} | ${o.filesize ? o.filesize : '--'}</div>
              <div class="low-layer">
                <span>${o.downloadnum ? `${o.downloadnum}人喜欢` : '--'}</span> |
                <span class="${color}">热度${o.proscore ? o.proscore : '--'}℃</span>
                <svg class="icon yui-icon-flame-${color}">
                  <use xlink:href="#icon-flame0" href="#icon-flame0"></use>
                </svg>
              </div>
            </div>
          </a>
          <div class="action-box">
            <a class="btn-dl yc-dbtn2" href="javascript:void(0)" ${constants.propertyName.download.url[isIos ? 'ipa' : 'apk']}="${link}">
              <svg class="icon">
                <use xlink:href="#icon-clock0" href="#icon-clock0"></use>
              </svg>
            </a>
          </div>
        </div>
      </li>
    `)[0]

    let el = node.querySelector('.btn-dl')

    download.init({
      attrOnly: true,
      btnClass: 'btn-dl',
      disableClass: 'btn-disabled',
      el,
      ...download.getArgs()
    })

    return node
  }
}

/**
 * 滑块初始化
 */
function swipersInit() {
  let browser = dataBus.get({
    key: 'browser',
  })

  // 轮播图
  swiper.init({
    el: '#swiper0'
  })

  // 热门手游滑块
  swiper.init({
    el: browser.ios ? '#swiper1-ios' : '#swiper1-android',
    options: {
      autoplay: false,
      initialSlide: 0,
      slidesPerView: 'auto'
    }
  })

  // 好评游戏滑块
  swiper.init({
    el: browser.ios ? '#swiper2-ios' : '#swiper2-android',
    options: {
      autoplay: false,
      initialSlide: 1,
      slidesPerView: 'auto'
    }
  })

  // 专题游戏滑块
  swiper.init({
    el: '#swiper3',
    options: {
      autoplay: false,
      slidesPerView: 'auto'
    }
  })
}

/**
 * 下载按钮初始化
 */
function downloadInit() {
  download.init({
    btnClass: 'btn-dl',
    disableClass: 'btn-disabled',
    el: '.btn-dl',
    ...download.getArgs()
  })
}

/**
 * android / ios 元素显示
 */
function showEl() {
  show.el({
    android: {
      bEl: '.android-b',
    },
    ios: {
      bEl: '.ios-b',
    },
    platform: constants.ua.platform[dataBus.get({
      key: 'browser'
    }).ios ? 'ios' : 'android'],
  })
}

/**
 * ui 初始化
 */
function uiInit() {
  showEl()
  componentsInit()
  downloadInit()
  broadcastInit()
  swipersInit()
  whenGetLgStatus()
  loadingMaskComponent.rm()

	/**
	 * 登录状态检测完毕
	 */
	async function whenGetLgStatus() {
		try {
			if (await memberShip.isLogin()) {
				let user = dataBus.get({
					key: 'entities',
				}).get('user')

				await user.queryGifts()
				user.setRcvedGifts()
			}
		} catch (e) {
			if (e && e.message) {
				utils.log.e(e.message)
			}
		}
	}
}

$(document).ready(() => {
  uiInit()
  bindEvts()
})