import { emptyBlkRemove } from './emptyBlkRemove'
import { init } from './init'
import * as utils from 'js/utils/index'

/**
 * 块导航标签初始化
 */
export function tagBlkInit (options = {}) {
  let {
    aTag,
    container,
    wrapper
  } = options

  if (!aTag || !container || !wrapper) {
    utils.log.e('[tagBlkInit] failed: invalid arguments')
    return
  }

  emptyBlkRemove(options)
  init(options)
}