// jQuery
import $ from 'jquery'

/**
 * 块导航标签切换
 * @param {Object} aTag "更多" a元素
 * @param {Object} container 块标签容器元素
 * @param {Object} wrapper navTag 容器元素
 */
export function init(options = {}) {
  let {
    aTag,
    container,
    wrapper
  } = options

  let blks = $(container).find('.display-block')

  if (blks.length === 0) {
    return
  }

  $(wrapper).on('click', '.yui-tag-nav', tagClk)

  initSelected()

  /**
   * 初始化选中
   */
  function initSelected() {
    let tags = $(wrapper).children('.yui-tag-nav')
    let selectedTag = $(wrapper).children('.selected')

    if (selectedTag.length === 0) {
      // 未选中或选中块是空块已被删除
      if (tags.length > 0) {
        tags.eq(0).addClass('selected')
        blks.eq(0).addClass('selected')
        aTag.setAttribute('href', blks.eq(0).attr('data-href'))
      }
    } else {
      // 存在被选中标签，选中对应块
      let index = Array.from(tags).findIndex(ele => {
        return ele === selectedTag[0]
      })

      blks.eq(index).addClass('selected')
      aTag.setAttribute('href', blks.eq(index).attr('data-href'))
    }
  }

  function tagClk(evt) {
    let tagsWrapper = $(this).parent()
    let selectedBlk

    $(this).siblings('.selected').removeClass('selected')
    $(this).addClass('selected')

    for (let [index, ele] of Object.entries(tagsWrapper.children())) {
      if (ele === this) {
        if (blks.length >= (Number.parseInt(index) + 1)) {
          // 存在标签索引对应的块
          selectedBlk = blks.eq(index)
          selectedBlk.addClass('selected')
          selectedBlk.siblings('.display-block').removeClass('selected')

          if (aTag) {
            aTag.setAttribute('href', selectedBlk.attr('data-href'))
          }
        }
        break
      }
    }
  }
}